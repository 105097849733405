<template>
  <div style="padding: 1rem">
    <div
      style="
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
      "
    >
      <div style="display: flex; flex-flow: row wrap; align-items: center">
        <img
          style="width: 10px; height: auto"
          src="../assets/common-01.png"
          @click="goBack"
        />
        <span style="margin-left: 1rem; font-size: 1rem; font-weight: bold">{{
          $t("record.item_01")
        }}</span>
      </div>

      <img
        style="width: 15px; height: auto"
        src="../assets/record-01.png"
        @click="goOrder"
      />
    </div>

    <div style="padding: 1rem 0">
      <PkgInfo :init="false" ref="PkgInfo" @sendList="setList" />
    </div>

    <p style="margin: 0; font-size: 1rem; font-weight: bold">
      {{ $t("record.item_02") }}
    </p>

    <div class="row">
      <div
        class="col-12"
        style="
          padding: 1rem;
          text-align: center;
          font-size: 1rem;
          color: #989898;
        "
        v-if="!httpProcess && (!list || list.length < 1)"
      >
        {{ $t("common.item_06") }}
      </div>

      <q-card
        style="margin: 1rem 0 0 0"
        class="col-12"
        v-for="(item, index) in list"
        :key="index"
        v-else
      >
        <q-card-section>
          <p style="margin: 0; font-size: 1rem; font-weight: bold">
            {{ item.title }}
          </p>
          <div class="row" style="color: #989898">
            <div class="col-4">
              <p style="margin: 0">{{ $t("record.item_03") }}:</p>
              <p style="margin: 0">{{ $t("record.item_04") }}:</p>
            </div>
            <div class="col-8">
              <p style="margin: 0">{{ item.active_end }}</p>
              <p style="margin: 0">
                <span v-if="item.status_code === -1" style="color: red">{{
                  $t("record.item_05")
                }}</span>
                <span v-if="item.status_code === 0" style="color: #989898">{{
                  $t("record.item_06")
                }}</span>
                <span v-if="item.status_code === 1" style="color: #00d557">{{
                  $t("record.item_07")
                }}</span>
              </p>
            </div>
          </div>
          <q-linear-progress
            style="border-radius: 10px; margin-top: 0.5rem"
            color="blue-8"
            size="18px"
            :value="parsePct(item.pct)"
          >
            <div
              style="
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 30%;
                font-size: 0.5rem;
                padding: 0 0 0 0.5rem;
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                justify-content: flex-start;
              "
              :style="{ color: parsePct(item.pct) > 0.3 ? '#fff' : '#000' }"
            >
              {{ $t("record.item_08") }}: {{ item.data_usage }}
            </div>
            <div
              style="
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 30%;
                font-size: 0.5rem;
                padding: 0 0.5rem 0 0;
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                justify-content: flex-end;
              "
              :style="{ color: parsePct(item.pct) < 0.7 ? '#989898' : '#fff' }"
            >
              {{ $t("record.item_09") }}: {{ item.total_data }}
            </div>
          </q-linear-progress>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
import PkgInfo from "@/components/pkg.vue";
export default {
  components: {
    PkgInfo,
  },
  data() {
    return {
      httpProcess: false,
      list: [],
    };
  },
  mounted() {
    this.$refs.PkgInfo.getData();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goOrder() {
      this.$router.push({ path: "/order" });
    },
    setList(list) {
      this.list = list;
    },
    parsePct(v) {
      return parseFloat((v / 100).toFixed(2));
    },
  },
};
</script>
